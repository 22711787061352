import React , { useRef, useState, useEffect, useContext }  from 'react';
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import { FireBaseContext } from "../../views/Chat"
import { encryptApp } from '../../utils/index.js';
import moment from 'moment-timezone';
// import _ from "lodash";


function ChatMessage () {

    const { messages,user, firebase, currentcustomer, getTime , getDetails} = useContext(FireBaseContext);
    const ref = useRef()

    const [formValue, setFormValue] = useState('');
    const [msg, setMsg] = useState([]);
    const [typing, setTyping] = useState(false);
    const [newMessage, setNewMessage] = useState(false);
    const [emoji, setEmoji] = useState(false);
    const [details, setRiderDetails] = useState({});
    const msgcount = useRef(messages.length)

    const dummy = useRef();    
  
    const sendMessage = async (e) => {

        e.preventDefault();
        const message = formValue;
        setEmoji(false) 
        setFormValue('');

        const id = firebase.database().ref().child('mRSZOvTBpl0NUY7Y').push().key;
        firebase.database().ref('mRSZOvTBpl0NUY7Y').child(msg[0].uniqueid).child("AkXRrKhLyQJHQl77").child(id).set({

            HMleGmzrq5TJaTRL: encryptApp("web"), //codebase
            qoKqsPPgGGMbZlVN: encryptApp(moment.tz('Africa/Nairobi').format('YYYY-MM-DD HH:mm:ss Z')), //datetime
            vzra0puhTcj9Q6uo: encryptApp(msg[0].id), //id
            zPjl9dc88jPug8qj: false, //isread
            IJeuMtTyUu0675f6: encryptApp(user.email), //phone
            v4b1RhYa7BOeqcSA: encryptApp(message), //message
            svSNDCpJGp68X0ag: encryptApp("chat-agent"), //type
          

        });    
        
        dummy.current.scrollIntoView({ behavior: 'smooth' });

    }

    const updateTyping = (e) => {

        setFormValue(e.target.value);

        // setTimeout(() => {
            
        //     firebase.database().ref('mRSZOvTBpl0NUY7Y').child( msg[0].uniqueid).child("Du27S4li10B9EhgT").set({
        //         TO0dypFCiThzooC8: encryptApp('typing ...')
        //     });
        // }, 3000);
    
      
    }

    const disableTyping = () => {

        setTimeout(() => {            

            firebase.database().ref('mRSZOvTBpl0NUY7Y').child(msg[0].uniqueid).child("Du27S4li10B9EhgT").update({
                TO0dypFCiThzooC8:''
            });
       
        }, 5000);        
        
    }

    const addEmoji = e => {
        let emoji = e.native;
        setFormValue(
            formValue + emoji
        );
    }

    const checkIfClickedOutside = e => {
            
        if (emoji && ref.current && !ref.current.contains(e.target)) {
          setEmoji(false)
        }
    }

    const trackScrolling = () => {        

        const el = document.getElementById('chatbox');
        if(el.scrollTop !== 0){

            let unread = messages.filter(  c => { return c.type === 'rider' && c.isread !== true && c.uniqueid === currentcustomer.uniqueid }).length
            console.log(unread)
            unread ? setNewMessage(true) : setNewMessage(false);
        }

        
    }


    const read = () => {

        if(Object.entries(currentcustomer).length ){

            firebase.database().ref('mRSZOvTBpl0NUY7Y').child(currentcustomer.uniqueid).child("Du27S4li10B9EhgT").once('value').then(function(snapshot) {

                    if(!snapshot.child("tZySlASZUOadbXZS").exists()){
                        firebase.database().ref('mRSZOvTBpl0NUY7Y').child(currentcustomer.uniqueid).child("Du27S4li10B9EhgT").update({
                            cFySetWktfodbFKH:encryptApp('https://portal.little.bz/img/little.png'),
                            tZySlASZUOadbXZS:encryptApp(user.FullName)
                        });
                    }

            })

           
        }

        let currentmsg = messages.filter( message => message.uniqueid === currentcustomer.uniqueid);

        currentmsg.filter(  c => { return c.type === 'rider' } ).forEach( message => {    

            firebase.database().ref('mRSZOvTBpl0NUY7Y').child( message.uniqueid).child("AkXRrKhLyQJHQl77").child(message.child).update({
                zPjl9dc88jPug8qj:true
            });

        });

        setNewMessage(false)
        dummy.current.scrollIntoView({ behavior: 'smooth' });
    
    }

  
    useEffect(() => {       

        document.addEventListener("mousedown", checkIfClickedOutside)
        document.getElementById("chatbox").addEventListener('scroll', trackScrolling);

        return () => {
            // Cleanup the event listeners
            document.removeEventListener("mousedown", checkIfClickedOutside)
            if( document.getElementById("chatbox")){

                document.getElementById("chatbox").removeEventListener('scroll', trackScrolling)
            }
          }

    } , [])



    useEffect(() => {         

        read();

        let currentmsg = messages.filter( message => message.uniqueid === currentcustomer.uniqueid);

        // const el = document.getElementById('chatbox');
        // if(el.scrollTop !== 0){

            let unread = messages.filter(  c => { return c.type === 'rider' && c.isread !== true && c.uniqueid === currentcustomer.uniqueid }).length
          
            unread ? setNewMessage(true) : setNewMessage(false);
        // }

        
        
        // if( document.getElementById('chatbox').scrollTop !== 0 ){

        //     currentmsg.filter(  c => { return c.type === 'rider' } ).forEach( message => {
    
        //         firebase.database().ref('mRSZOvTBpl0NUY7Y').child( message.uniqueid).child("AkXRrKhLyQJHQl77").child(message.child).update({
        //             zPjl9dc88jPug8qj:true
        //         });
    
        //     });
        // }

        msg.filter(  c => { return c.type === 'rider' && c.uniqueid === currentcustomer.uniqueid && c.typing?.length > 0 }).length > 0 ? setTyping(true) : setTyping(false)        
        setMsg( currentmsg ); 
        if(currentcustomer?.customer){

            setRiderDetails( getDetails(currentcustomer.customer));
        }

        if(msgcount.current  < messages.length){
            msgcount.current = messages.length
            // console.log(messages.length)
        }
        

    },[ msgcount.current.length, currentcustomer.customer, emoji,getDetails ]) 

    return (
        <div className="col-sm-9 col-md-6 col-9" id="chatmessage">
            
           
           <div className="d-flex justify-content-start p-2 mh-100">
            { currentcustomer.customer && 
            <>
                <img className="rounded-circle" width={50} height={50} src={details?.ProfilePicture || "https://littleimages.blob.core.windows.net/app/SuperApp/Templates/default/MYPROFILE.png" }  alt="rider profile" />
                <div className="ml-3">
                    <b>{details?.FullName || currentcustomer.customer}  #{currentcustomer.orderid.substring(0,6)}</b>
                    <br/>
                    { msg.length > 0 && <small className="text-muted">{ getTime(msg[0].datetime || moment.now())}</small>}
                </div> 
            </>
            }
            </div> 

           

        <div className="d-flex flex-column flex-column-reverse px-3 my-3" id="chatbox"> 

            { newMessage && 
        
                <span className="badge round text-dark bg-white p-2 col-3 align-self-center mb-2 pointer" onClick={ () => { read() } } style={{"zIndex":99,  "position": "absolute"}}> 
                    New Messages <i className="bi bi-arrow-down-circle-fill"></i> 
                </span> 
            }              

            <span ref={dummy}></span>       
           
            { typing && 
            <> 
                <img className="rounded-circle" width={30} height={30} src={details?.ProfilePicture ? details.ProfilePicture : "https://littleimages.blob.core.windows.net/app/SuperApp/Templates/default/MYPROFILE.png" }  alt="rider profile"  />
                <div className="ml-5 typing-indicator">
                    <span className = "dot-1"></span>
                    <span className = "dot-2" ></span>
                    <span className = "dot-3" ></span>
                </div>
            </>
            }

            { msg && msg.map((doc,index) => (
                <div className={ `d-flex mb-2 ${doc.type === 'chat-agent' ? "ml-auto" : "flex-row-reverse justify-content-end "}`} key={`${index}-${doc.id}`}>
                    
                    <div className= { `mx-2 ${ doc.type === 'chat-agent' ? "chat-round text-white bg-pink" : "chat-round-left bg-white py-2" } `}>
                        
                        <span>{ doc.text }</span>
                        { doc.type === 'chat-agent'  && <sub> <i className={`mt-2 float-right bi bi-check${doc.isread ? '-all': ''} `}></i> </sub> } 
                        
                        { doc.type === 'rider'  && <div><sub className="text-muted">sent from { doc.codebase === 'apple' ? 'iPhone' : doc.codebase } { getTime(doc.datetime ) } ago </sub></div> } 
                        { doc.type !== 'rider'  && <div className="text-right"><sub> { getTime(doc.datetime ) } ago</sub></div> } 
                        
                        
                    </div>
                    <img  className={`align-self-end rounded-circle ${ doc.type === 'chat-agent' ? "float-right" : ""}`} width={30} height={30} src={ doc.type === 'chat-agent' ? 'https://portal.little.bz/img/little.png' : details?.ProfilePicture} alt="sender profile"  />
                   
               
                </div>

            ))}




        
        </div>



  
        { currentcustomer.customer && 
        <form className="d-flex d-flex-row d-flex justify-content-between p-2" onSubmit = { sendMessage } >        
           
            <div className="input-group" ref={ref}>
                <span id="emoji" className="my-auto">
                    <button onClick={() => { 
                        setEmoji(!emoji) 
                        // document.getElementsByClassName('emoji-mart-bar')[0].style.visibility='hidden';
                        
                        } } className="btn btn-info round" type="button">
                        <i className="bi bi-emoji-smile"></i>
                    </button>
                    <button disabled={ formValue.trim().length === 0 } type="submit" className="btn btn-primary round m-2" ><i className="bi bi-chat-left-quote-fill"></i></button>
                    { emoji && 
                 
                            <Picker                           
                            style={{ position: 'absolute', bottom: '55px', right: '0px' }} 
                            showPreview={false}
                            title=''
                            onSelect={addEmoji} 
                            />                   
                    }
                </span>
                
                {/* <input type="text" id="messagetext"  value={formValue} onKeyUp={disableTyping} onChange={(e) => updateTyping(e)}  className="form-control py-3 round" placeholder="Type a message here" aria-label="Search"
                    aria-describedby="basic-addon1"/> */}
                <textarea  id="messagetext"  value={formValue} onKeyUp={disableTyping} onChange={(e) => updateTyping(e)}  className="form-control py-3 round" placeholder="Type a message here"></textarea>
                

                    
                    
            </div>

            

        </form>}

        </div>
    );
}

export default  React.memo(ChatMessage);