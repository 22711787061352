import React, { useContext } from 'react';
import { FireBaseContext } from '../../views/Chat';



function SideBar() {

    const { setUser } = useContext(FireBaseContext);

    return (
        <nav id="sidebar" className="active mt-5 d-none d-md-block">
            {/* <img width="60" className="mb-2" src="https://portal.little.bz/img/little.png" alt="little logo" /> */}
            <p className="h4 text-white">LF</p>
            <button onClick={() => { 
                        setUser('') 
                        localStorage.removeItem("user")  
                        } } className="btn bg-pink round text-white" type="button">
                        <i className="bi bi-power"></i>
            </button>
        </nav>
    );
}

export default SideBar;