import React , { useContext } from "react";
import Lottie from "react-lottie";
import animationData from '../../assests/images/offline.json'
import { FireBaseContext } from "../../views/Chat";

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

const Offline = () => {

    const {isOnline} = useContext(FireBaseContext)

    return ( 
      

<div className={`offcanvas offcanvas-bottom ${!isOnline ? 'show': ''}` } tabIndex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
<div className="offcanvas-header text-center">
  <h5 className="offcanvas-title col" id="offcanvasBottomLabel">YOU ARE  <span className="text-muted">OFFLINE</span></h5>
  
  <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
</div>
<div className="text-center">
<small>Dut searching for network ... </small>
<Lottie id="loading-image" options={defaultOptions}
              height={130}
              width={130}
              />
</div>
</div>
     );
}
 
export default Offline;