import React, { useContext, useEffect, useState } from 'react';
import { FireBaseContext } from '../../views/Chat';
import logo from '../../assests/images/chat.jpg';



function ChatDetails() {
    const [order,setOrder] = useState({});
    const { currentcustomer } = useContext(FireBaseContext);

    const getDetails= () => {
        setOrder({})
        return fetch('https://stats.little.bz/ride/delivery',{
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({ tripid:currentcustomer.orderid })
        })
        .then((response) => response.json())
        .then((data) => {
            if(data.success){
               setOrder(data.trip)
            }else{
               setOrder({})
            }
        })
        .catch(() =>setOrder({}) );
    }


    useEffect(()=>{

        if(currentcustomer.customer){

            getDetails();
        }

    }, [currentcustomer.customer])


    return (
        <div className="col-3 shadow round bg-white d-flex flex-column justify-content-center py-5 d-none d-md-block" id="chatdetails">
        <div className="text-center">

            <img className="rounded-circle mb-2" width={120} height={120} src={currentcustomer.details?.ProfilePicture || logo }  alt="rider profile" />
            <p className="h6" >{ currentcustomer.details?.FullName || 'Little Food'}</p>
            <p className="h6" >{currentcustomer.customer || ''}</p>
        </div>
     
        { Object.entries(order).length > 0 && 
        <div className="accordion accordion-flush" id="accordionFlushExample">      
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                    <button className="accordion-button collapsed" type="button" aria-expanded="false" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-controls="flush-collapseOne">
                        Order Details  <small className="badge badge-pill bg-pink"> { order?.ModuleID } </small>
                    </button>
                </h2>
                
                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                        <p className="h6 text-center">{ order["merchant.Name"]?.toUpperCase() || '' }   </p>
                        
                        <ul>
                            <li>Order ID: { `#${ order.TripID?.substring(0,6)}` || ''}</li>
                            <li>Food:  { order?.Balance || ''} x {order['DeliveryTripDetails.Quantity']}</li>
                            <li>OrderAmount: Ksh { order['DeliveryTripDetails.Price'] || ''}</li>
                            <li>Rider Status: { order?.RiderStatus || ''}</li>
                    
                        </ul>
                    </div> 
                </div> 
            </div>
               
            <div className="accordion-item ">
                <h2 className="accordion-header" id="flush-headingTwo">
                    <button className="accordion-button collapsed" type="button" aria-expanded="false" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo"  aria-controls="flush-collapseTwo">
                        Trip Details
                    </button>
                </h2>

                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                        <p className="h6 text-center">{`#${ order.TripID?.substring(0,6)}`} </p>
                        <ul>
                            <li>TripStatus : { order?.TripStatus || ''}</li>
                            <li>Delivery Charges : Ksh { order?.DeliveryCharges || ''}</li>
                            <li>DropOff Address : {order?.DropOffAddress || ''}</li>                    
                        </ul>
                    </div>
                </div>
            </div>
        </div>}

            <figure className="text-center d-none d-lg-block">
                <blockquote className="blockquote h5">
                    <p>Your most <mark className="bg-pink badge">unhappy</mark> customers are your greatest source of learning.</p>
                </blockquote>
                <figcaption className="blockquote-footer h6">
                    Bill Gates
                </figcaption>
            </figure>
        </div>
    );
}

export default ChatDetails;