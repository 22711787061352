import React, { useContext, useEffect, useRef, useState } from 'react';
import { FireBaseContext } from '../../views/Chat';
import _ from "lodash";
import moment from 'moment';


function ChatList() {

    const { messages, currentcustomer, setCustomer,setUserList,getTime, isOnline, setLoading,switchChat ,table} = useContext(FireBaseContext);
    const [customers, setCustomers] = useState({});
    const [filtered, setFiltered] = useState([]);
    const [min, setMin] = useState("");
    const notification = useRef([]);
    const msgcount = useRef(0);
 

    // const params = useMemo(() => {
    //     return { param1, param2, param3: 5 }
    //   }, [param1, param2])
    
    const handleSwitch = chat => {
        switchChat(chat);
    }


    const updateRead = (customer, index, msg) => {
        
        let temp_customers = [ ...customers ];

        temp_customers[index] = {...temp_customers[index], unreadCount: 0}; 

        setCustomer({customer, uniqueid:msg.uniqueid,orderid:msg.messages[0].id,details:msg.details });

        // temp_customers.filter(function(cus) { return cus.customer === customer }).forEach( value => {
          
        //     value.messages.forEach( message => {

        //         firebase.database().ref('mRSZOvTBpl0NUY7Y').child( message.key).child("AkXRrKhLyQJHQl77").child(message.child).update({
        //             zPjl9dc88jPug8qj:true
        //         });

        //     })
            
        // }
               
            
        
    }

    const notify = () => {
                
            notification.current.map( (notify) => {

                if(!localStorage.getItem('notify')){

                    localStorage.setItem('notify', JSON.stringify([notify]))
             
                }else{

                    let a = JSON.parse(localStorage.getItem('notify'));
                 
                    if( !a.some(n => n.child === notify.child)){

                        a.push(notify);
                        localStorage.setItem('notify', JSON.stringify(a));
                        new Notification(notify.name || 'New Message',{
                            body: notify.text,
                            icon: notify.profile|| 'https://littleimages.blob.core.windows.net/app/SuperApp/Templates/default/MYPROFILE.png',
                            timestamp: Math.floor(Date.now()),
                            dir: "ltr"})
                    }

                }

            })                
        
    }

    const search = (search_key) => {

        let filtered_customers = customers.filter( data => {

            if(search_key == null)
                return data
            else if(data.details.FullName.toLowerCase().includes(search_key.toLowerCase()) || data.customer.toLowerCase().includes(search_key.toLowerCase())){
                return data
            }
        });

        setFiltered(filtered_customers);

    }

   


    useEffect(() => {         
        

        let currectnotification = [];
        let grouped_customers = _.groupBy(messages.filter(  c => { return c.type === 'rider' }), 'uniqueid');

        grouped_customers = Object.keys(grouped_customers).map( cus => {
  
            let unread = grouped_customers[cus].filter( c => {return c.isread !== true });
          
            currectnotification.push(...unread);   
            

            return {
                customer: grouped_customers[cus][0].mobilenumber,
                messages: grouped_customers[cus].reverse(),
                details:{
                    
                    FullName:grouped_customers[cus][0].name,
                    MobileNumber:grouped_customers[cus][0].mobilenumber,
                    ProfilePicture:grouped_customers[cus][0].profile ? grouped_customers[cus][0].profile : "https://littleimages.blob.core.windows.net/app/SuperApp/Templates/default/MYPROFILE.png"                

                },
                unreadCount : unread.length,
                uniqueid : grouped_customers[cus][0].uniqueid,
                typing: grouped_customers[cus].filter(  c => { return c.type === 'rider' && c.typing?.length > 0 }).length,
               
            };

        });       
        setUserList(grouped_customers.map(a => a.details))
        setCustomers( grouped_customers );
        setFiltered( grouped_customers );
        setLoading(false); 
        
        notification.current = currectnotification
 

        if(notification.current.length){
            notify();
        }
        
        // if(messages.length){
        //     msgcount.current = messages.length
        //     console.log(messages.length)
        // }
              
        const intervalID = setInterval(() => {

            setMin(moment.now)
            
        }, 300000);

        return () => clearInterval(intervalID);
        
    } , [messages.length,isOnline,min,table,setLoading, setUserList])


    return (
        <div id="chatlist" className="col-3 bg-white py-4 shadow-lg d-flex flex-column">
            <div className="form-group">

            <select className="form-select round" onChange={ e => handleSwitch(e.target.value)} aria-label="Default select example">
                <option>DELIVERY</option>
                <option>COVID</option>
                <option>DOCTOR</option>
            </select>
            </div>

            <h2 className="font-weight-bold">Chat
                <span className={`position-absolute translate-middle mx-2 p-2 ${isOnline ? 'bg-pink':'bg-secondary'} border border-light rounded-circle`}>
                    <span className="visually-hidden">New alerts</span>
                </span>
            </h2>
            <small className="text-muted">You are <b className={isOnline ? "text-pink":"text-muted"}>{isOnline ? 'Online':'Offline'}</b></small>

            <div className="round d-flex my-4 " 
            style={{border: "solid 1px #cecece",
            padding: "5px 5px"}}>
                <input type="text" className="form-control mx-2" style={{
                    border: "none",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    paddingLeft: "3px",
                    paddingRight: "3px",
                    height: "auto",
                    boxShadow: 'none'
                }} placeholder="Search"
                onChange = { (e) => search(e.target.value)}
            />
            <span className="d-block text-muted px-1">
            <i className="bi bi-search"></i>
            </span>
            </div>

            <div className="h-100" id="chatusers">

            { customers ? Object.keys(filtered).map((cus,index) => (
                
                <div onClick={ () => updateRead(filtered[cus].customer , cus ,filtered[cus]) } className={`d-flex justify-content-start align-items-center p-2 pointer ${index !== Object.keys(filtered).length - 1 ? 'border-bottom':''} ${filtered[cus].uniqueid === currentcustomer.uniqueid ? 'active'  : ''}`} key={filtered[cus].messages[0].uniqueid}>
                   
                    <img className="rounded-circle d-none d-lg-block" width={70} height={70} src={ filtered[cus].details.ProfilePicture || "https://littleimages.blob.core.windows.net/app/SuperApp/Templates/default/MYPROFILE.png" } alt="rider profile" onError={(e)=>{e.target.onerror = null; e.target.src="https://picsum.photos/70/70" }} />
                  
                    <div className="ml-3">
                        <b>
                            { filtered[cus].details?.FullName || filtered[cus].customer }

                            { filtered[cus].unreadCount > 0 &&            
                             
                                <span className="badge bg-pink round mx-3"> {filtered[cus].unreadCount} </span>
                            }
                        </b>

                        <br/>

                        { Object.entries(messages).length && !filtered[cus].typing && 
                            <small className="text-muted">
                                {  getTime(_.orderBy(messages.filter( (m) => { return m.uniqueid === filtered[cus].uniqueid }), function(o) { return new moment(o.datetime); }, ['desc'])[0].datetime || moment.now()) } 
                            </small> 
                        }
                        
                        { Object.entries(messages).length && filtered[cus].typing > 0 && 
                            <div className="typewriter">
                                <small className="typewriter-text text-muted"> Typing ... </small>
                            </div>  
                        }  
                        
                    </div>
                    
                </div>

                )) : <p>No chats</p>
            }
                
                

            </div>
        </div>
    );
}


export default ChatList;