import { useContext } from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assests/images/loading.json'
import { FireBaseContext } from '../../views/Chat';

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

const Loading = () => {   

  const { loadingMsg } = useContext(FireBaseContext)

    return ( 
        <div id="loading">
          <Lottie id="loading-image" options={defaultOptions}
              height={400}
              width={400}
              />
            <b className="text-white"> {loadingMsg} </b>
          </div>
     );
}
 
export default Loading;