const _crypt = require('cryptlib');

const key = process.env.KEY || "f080786e3a348458a621e2fa4c267ad8"; // Must be 256 bits (32 characters)
const iv = process.env.iv || "84jfkfndl3ybdfkf";

const ivApp = 'i7otp4rq4ysrdh5v'
const keyApp = 'b8dc2e31a23d0ef8e25f5e49f8559584'

function encrypt (plainText) {
    return _crypt.encrypt(plainText, key, iv);
}

function decrypt (cypherText) {
    return _crypt.decrypt(cypherText, key, iv);
}

function encryptApp (plainText) {

    try {        
        return _crypt.encrypt(plainText, keyApp, ivApp);
    } catch (error) {
        console.log(error,plainText)
    }
}

function decryptApp (cypherText) {
   

    try {
         return _crypt.decrypt(cypherText, keyApp, ivApp);
    } catch (error) {

        return cypherText
    }
   
}

module.exports = { decrypt, decryptApp, encrypt ,encryptApp };
