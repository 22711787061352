import Lottie from 'react-lottie';
import animationData from '../../assests/images/plane.json'

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

const Plane = () => {   

    return ( 
        <div id="plane" className="d-flex align-items-center">
          <Lottie id="loading-image" options={defaultOptions}
              height={500}
              width={500}
              />
        </div>
     );
}
 
export default Plane;