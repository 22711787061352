import React from 'react';
import Offline from '../Global/Offline';
import SideBar from '../layout/SideBar';


function ChatContent({ children }) {


    return (
        <div className="wrapper d-flex align-items-stretch my-auto">
        
        <SideBar/>
        <div id="content" className="mx-4 shadow bg-offwhite">

          <Offline/>
        <div className="row">          

          { children }     

        </div>                     
        </div>   
    </div>
    );
}

export default ChatContent;
