import React, { useContext, useState } from 'react';
import logo from '../assests/images/msg.png';
import Plane from '../components/Global/Plane';
import { FireBaseContext } from './Chat';


function Login() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const { setUser } = useContext(FireBaseContext);

    const submitLogin = e => {
        setError('');
        setLoading(true);
        e.preventDefault();
        fetch('https://stats.little.bz/login/chat', {
          method: 'POST',
          body: JSON.stringify({ email,password }),
          headers: { 'Content-Type': 'application/json' },
        })
          .then(res => res.json())
          .then(data => {
            setTimeout(() => { 
              if(data.success){
                localStorage.setItem("user", JSON.stringify(data.user));
                setUser(data.user);
              }else{
                setError("Incorrect User/Password");
              }
            }, 2000);
          })
          .finally(() => {  
            setTimeout(() => {                         
             setLoading(false);       
            }, 2000);    
          })
      }
    


    return (
        <>
        { loading && <Plane/> }
        <div id="login-content"  className="wrapper d-flex align-items-stretch justify-content-center my-auto">
        
       
        <div className=" d-flex justify-content-center text-center mx-auto my-auto p-3 w-27 round shadow bg-white">

        <div className="p-4">
        {error && <div className="alert alert-danger" role="alert">
                    {error}
                </div>}
            <img className="card-img-top" src={logo}  alt="message icon"/>
        
            
            <form onSubmit={ submitLogin }>
              
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input type="email"  value={email}  onChange={e => setEmail(e.target.value.toLowerCase())} className="form-control round" required aria-describedby="emailHelp" placeholder="Enter email"/>
                   
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Password</label>
                    <input type="password" value={password}  onChange={e => setPassword(e.target.value)} className="form-control round" required id="exampleInputPassword1" placeholder="Password"/>
                </div>
                <button type="submit"  className="btn round bg-pink text-white font-weight-bold my-2 p-3">LET'S GET CHATTING</button>
                    
            </form>
            
        </div>
              
        </div>   
    </div>
    </>
    );
}

export default Login;
