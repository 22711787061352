import React, { useCallback, useEffect, useState } from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import _ from "lodash";
import moment from "moment";
import firebase from "firebase/app";
import "firebase/database";
import 'firebase/auth';


//componets
import ChatContent from "../components/Chat/ChatContent.js";
import ChatDetails from "../components/Chat/ChatDetails.js";
import ChatList from "../components/Chat/ChatList.js";
import ChatBox from "../components/Chat/ChatMessage.js";
import Login from '../views/Login.js';
import Loading from '../components/Global/Loading.js';
import { decryptApp } from '../utils/index.js';
import ErrorFallback from '../components/Global/ErrorFallback.js';

require('dotenv').config()

//context
export const FireBaseContext = React.createContext();

const fireApp = firebase.initializeApp({
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_DOMAIN,
  databaseURL: process.env.REACT_APP_DBURL,
  projectId:process.env.REACT_APP_PROJECTID,
  storageBucket:process.env.REACT_APP_STORAGE,
  messagingSenderId: process.env.REACT_APP_SENDERID,
  appId: process.env.REACT_APP_APPID
})


const auth = firebase.auth();
auth.signInAnonymously().catch(alert);


export default function Chat() {

  const [ user, setUser ] = useState(JSON.parse(localStorage.getItem("user")));
  const [ table, setTable ] = useState('mRSZOvTBpl0NUY7Y');
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [currentcustomer, setCustomer] = useState({});
  const [userlist, setUserList] = useState([]);
  const [isOnline, setNetwork] = useState(window.navigator.onLine);
  const loadingMsg = "HI I'M BLUE 😇 WAMBUI'S RIGHT HAND , JUST FINISHING UP CONFIGURATION ";


  const switchChat = key => {

    switch (key) {
      case 'DELIVERY':
        setTable('mRSZOvTBpl0NUY7Y')
        break;
      case 'DOCTOR':
        setTable('vLygQTvtcAivGXHt')
        break;
      case 'COVID':
        setTable('c3jyM2uQbItlZXAE')
        break;
    
      default:
        setTable('mRSZOvTBpl0NUY7Y')
        break;
    }

  }




  const getMessages =  useCallback( async() => {

      await fireApp.database().ref(table).orderByChild('qoKqsPPgGGMbZlVN').on("value", snap => {
            
        let groups = []; 
        // setMessages([]);
        if(snap.val()){

     
          Object.entries(snap.val()).forEach(
              ([index, snap]) => {   
  
              if(snap["AkXRrKhLyQJHQl77"]){
  
              
                Object.entries(snap["AkXRrKhLyQJHQl77"]).forEach( ( [key, value]) => {

  
                  if(Object.keys(value).length && decryptApp(value["svSNDCpJGp68X0ag"]) !== 'driver' && value["vzra0puhTcj9Q6uo"]){
                    let typing = snap["Du27S4li10B9EhgT"]?.rvywLiFBCxEBCKkz
                    let ridername = snap["Du27S4li10B9EhgT"]?.mHGFSlASZUOadkhT
                    let profile = snap["Du27S4li10B9EhgT"]?.gJYSetWktfodbFKH
      
                      groups.push({
  
                          id:decryptApp(value["vzra0puhTcj9Q6uo"]) || '', 
                          mobilenumber:decryptApp(value["IJeuMtTyUu0675f6"]) || '',
                          name: decryptApp(ridername)|| '' ,
                          profile: decryptApp(profile) || '' ,
                          text : decryptApp(value["v4b1RhYa7BOeqcSA"]) || '', 
                          type : decryptApp(value["svSNDCpJGp68X0ag"]) || '', 
                          isread : value["zPjl9dc88jPug8qj"] || '', 
                          codebase: decryptApp(value["HMleGmzrq5TJaTRL"]) || '', 
                          datetime: decryptApp(value["qoKqsPPgGGMbZlVN"]) || '',
                          uniqueid:index,
                          child: key,
                          typing: decryptApp(typing)                           
  
                      })
  
                  }            
                  
                });          
  
              }
          });     
          groups = _.orderBy(groups, function(o) { return new moment(o.datetime) }, ['desc']);
          

            setMessages(groups);      
         
          // getRiders(Object.keys(_.groupBy(groups.filter(  c => { return c.type === 'rider' }), 'mobilenumber')));    

        }


           
    })

    
  }, [table])

  const getDetails = mobile => {   

    return(userlist.find( rider => rider.MobileNumber === mobile ));

  }

  const getTime = (d) => {

      const now  = moment.now();
      const diff = moment.duration(moment(d).diff(moment(now))).humanize();
  
      return diff;

  }

  const updateNetwork = () => {
    
     setNetwork(window.navigator.onLine);

  };

 
  useEffect(() => {

    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
 
    if(user){

      getMessages();
    }
    
    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);

  },[user,table])
     
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
      <FireBaseContext.Provider value={{ firebase, messages, user, table,currentcustomer,setUserList, setCustomer,setUser, getDetails, getTime, isOnline, loadingMsg, setLoading,switchChat}}>
        { !user ? <Login/> :               
        <ChatContent>
          
            { loading && messages.length && <Loading/>}


            <>
              <ChatList/>
              <ChatBox/>
              <ChatDetails/>               
            </> 
            
        </ChatContent>
      }
      </FireBaseContext.Provider>
      </ErrorBoundary>

    )


}

  
